/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { constants } from '../../constants';

export class BlogCTA extends Component
{
  public render(): React.ReactNode
  {
    return (
      <div className="relative bg-gray-800 rounded-xl">
      <div className="h-56 bg-indigo-600 sm:h-72 rounded-xl md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage className="relative h-full rounded-xl shadow-lg" src="../../images/mockups/mockup-workout.jpg" alt="Structured workout for cyclist in Wattrainer" />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8 lg:py-8">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl"><Trans>Tailor-made training</Trans></p>
          <div className="mt-2">
            <div className="inline-flex rounded-md shadow">
              <a
                href={constants.links.signUp}
                target="_blank"
                className="inline-flex no-underline items-center cursor-pointer text-base font-bold justify-center px-5 py-3 rounded-md text-white bg-primary hover:bg-primary-600"
              >
                <Trans>➜ Get started</Trans>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}